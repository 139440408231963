import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="parent-div">
  <iframe loading="lazy" className="lazy-div"
    src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFNaNucgiQ&#x2F;view?embed" allowFullScreen={true} allow="fullscreen">
  </iframe>
</div>
 Made by Panicle Tech Private Limited
    </div>
  );
}

export default App;
